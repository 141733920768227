import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import {ElNotification} from "element-plus";
import {reactive, ref} from "vue";

const routes = [
    {
        path: '/',
        name: 'welcome',
        component: () => import('../views/WelcomeServer.vue'),
        children: [
            {
                path: '/kits',
                name: 'kits',
                component: () => import('../views/serverPage/KitsPage.vue')
            },
            {
                path: '/main',
                name: 'indexMain',
                component: () => import('../views/serverPage/Index.vue')
            },
            {
                path: '/managerServer',
                name: 'managerServer',
                component: () => import('../views/serverPage/ManagerServer.vue'),
                meta: {
                    requiresAuth: true,
                    roles:[
                        'admin'
                    ]
                }
            },
            {
                path: '/permission',
                name: 'permission',
                component: () => import('../views/serverPage/PermissionPage.vue'),
                meta: {
                    requiresAuth: true,
                    roles:[
                        'admin'
                    ]
                }
            },
            {
                path: '/deposit',
                name: 'deposit',
                component: () => import('../views/serverPage/DepositPage.vue')
            },
            {
                path: '/joinour',
                name: 'joinour',
                component: () => import('../views/serverPage/JoinUS.vue')
            },
            {
                path: '/contactAdmin',
                name: 'contactAdmin',
                component: () => import('../views/serverPage/ContactAdmin.vue')
            },
            {
                path: "/userManager",
                name: "userManager",
                component: () => import('../views/serverPage/UserManager.vue')/*,
                meta: {
                    requiresAuth: true
                }*/
            },
            {
                path: "/login",
                name: "login",
                component: () => import('../views/serverPage/Login.vue')
            },
            {
                path:"/menuManager",
                name: "menuManager",
                component:()=>import('../views/serverPage/MenuManager.vue')
            }
        ]
    }
]
function clearInfo(){
    localStorage.removeItem('userToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userMenu')
}
function checkLogin() {
    const userT = localStorage.getItem('userToken')
    const userI = localStorage.getItem('userInfo')
    if (userT != null && userI != null) {
        return true;
    }
    clearInfo()
    return false;
}
function getUserRole(){
    const userRole=JSON.parse(localStorage.getItem('userInfo'))
    return userRole.role.roleName+""
}
const router = createRouter({
    mode: 'history',
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, form, next) => {
    const excludedPaths = [
        '/deposit',
        '/kits',
        '/main',
        '/joinour',
        '/login',
        '/contactAdmin'
    ]
    let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth & !checkLogin() & !excludedPaths.includes(to.path)) {
        next('/login')
    }
    if (to.matched.some(record => record.meta.roles)){
        if (!to.meta.roles.includes(getUserRole())){
            ElNotification({
                message:'你无权限访问,正在重定向默认页面',
                type:'error'
            })
            next('/kits')
        }
    }
    next()
})

export default router;

