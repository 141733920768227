import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus, {ElNotification} from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import * as icons from '@element-plus/icons-vue'
import {createI18n} from 'vue-i18n'
import zh from './language/zh.json'
import en from './language/en.json'
const app=createApp(App,{
    productionTip:true
})

const i18n=createI18n({
    locale:'zh',
    legacy:false,
    messages:{
        'zh':zh,
        'en':en
    }
})
for (const [key, component] of Object.entries(icons)) {
    app.component(key, component)
}


app.config.errorHandler = (err, vm, info) => {
    // err 是错误对象，vm 是发生错误的 Vue 实例，info 是错误信息
    console.error('捕获到全局错误:', err);

    // 使用 Element Plus 通知错误信息
    try {

    }catch (err){
        console.log(err)
    }
    ElNotification({
        title: '错误',
        message: `发生错误：${err.message}`,
        type: 'error',
        duration: 5000 // 持续时间 5 秒
    });
};
app.use(i18n)
app.use(router);
app.use(ElementPlus);
app.mount('#app')
